
.is-mobile-app {
	.hide-app {
		display: none;
	}
	.deactivate-link-app {
		pointer-events: none;
		cursor: default;
		text-decoration: none;
		color: black;
	}
}

.is-website {
	.hide-ws {
		display: none;
	}
	.deactivate-link-ws {
		pointer-events: none;
		cursor: default;
		text-decoration: none;
		color: black;
	}
}