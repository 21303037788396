
.dialog-wrapper {
	.dialog-content {
		position: relative;
		width: 100%;
	}
	&.fullscreen {
		.dialog-content {
			display: flex;
			flex-basis: 1;
			flex-grow: 1;
			display: flex;
			overflow-y: auto;
		}
	}
}