.tabs-button-item,
.tabs-item //TODO: remove this line when old tabs removed
{
  @include tab-header-item-inactive;
	border-bottom: none !important;
	&.active {
		@include tab-header-item-active;
	}
	&.disabled {
		@include tab-header-item-disabled;
	}
	&:hover:not(.active) {
		@include ui-state-hover;
	}
}//