@mixin border-top-radius($size) {
	border-top-left-radius: $size;
	border-top-right-radius: $size;
}

@mixin border-bottom-radius($size) {
	border-bottom-left-radius: $size;
	border-bottom-right-radius: $size;
}

@mixin border-left-radius($size) {
	border-top-left-radius: $size;
	border-bottom-left-radius: $size;
}

@mixin border-right-radius($size) {
	border-top-right-radius: $size;
	border-bottom-right-radius: $size;
}

@mixin border-all-radius($size) {
	@include border-top-radius($size);
	@include border-bottom-radius($size);
}