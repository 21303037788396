.tabs.tabs-shared,
	.react-tabs {
		.tabs-headers,
		.tabs-nav {
			display: flex;
			margin: 0;
			min-height: fit-content;
			padding-top: .2em;
			padding-left: .3em;
			padding-right: .2em;
			padding-bottom: 0;
			@include ui-widget();
		}
		.tabs-pages,
		.tabs-layout {
			flex: 1;
			display: flex;
			flex-direction: column;
			@include ui-widget-content;
			& >	* {
				height: 100%;
			}
		}
	}
	
	
	.tabs, .react-tabs {
		.tabs-headers {
			.after-headers {
				list-style: none;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
			}
		}
	}