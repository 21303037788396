.dialog-window {
	overflow-y: auto;
	.button-close {
		background-color: transparent;
		border: none;
		right: 0;
		top: 0;
		user-select: none;
		outline: none;
		cursor: pointer;
		color: getColor("black") !important;

		span {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}