.table-base {
  &.colored {
    tr:not(.selected) {
      &.color-0 {
        background-color: transparent;
      }
      &.color-1 {
        background-color: #e8ecf0;
      }
      &.color-2 {
        background-color: lime;
      }
    }
  }

  thead {
    &.primary th {
      background-color: #b5d8e2;
    }
  }
  
  tr.selected {
    background-color: #2e6e9e;
    color: white;
    td[disabled] {
      background-color: #666666;
    }
  }

  // &.x2 tbody tr:nth-child(2n),
  // &.x2_1 tbody tr:nth-child(2n+1) {
  //   background-color: #e8ecf0;

  //   &.selected,
  //   &.active {
      
  //   }
  // }

  
}