body {
  @include if-debug {
    border: 2px solid #663399;
  }
  position: relative;
	height: 100%;
	width: 100%;
	top: 0;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	max-height: 100vh;
	min-height: 100vh;
	height: 100vh;
	background-color: #ffffff;
	line-height: 14px;
	color: #333;
	overflow: hidden;
	font-size: 14px;

	& > .panel:not(:nth-last-child(1)) {
		margin-bottom: 0;
	}
}

html, body {
  padding: 0;
  margin: 0;
}

html {
	-webkit-overflow-scrolling: touch;
	@include fontstyle("16-normal-red");
	margin: 0;
}

body {
	overflow-x: hidden;
	font-family: 'Open Sans', sans-serif;
}