$__margin-default: 1;

	.react-calendar-overrides {
		background-color: white;
		width: 17em;
		padding: 2px;
		.react-calendar__navigation__prev2-button,
		.react-calendar__navigation__next2-button {
			display: none;
		}
		.react-calendar__navigation__prev-button,
		.react-calendar__navigation__next-button {
			position: relative;
			border-radius: 50%;
			color: transparent;
			display: flex;
			flex-direction: row;
			content: "";
			justify-content: center;
			border-color: transparent;
			&,
			&:hover,
			&:focus,
			&:active {
				background-color: transparent !important;
			}
			&:after {
				content: "";
				width: 16px;
				height: 16px;
				display: block;
				background-image: url("/images/ui-icons_d8e7f3_256x240.png");
			}
		}
		.react-calendar__navigation__prev-button {
			&:after {
				background-position: -80px -192px;
			}
		}
		.react-calendar__navigation__next-button {
			&:after {
				background-position: -48px -192px;
			}
		}
		.react-calendar__navigation {
			@include ui-widget-header;
			border-radius: 3px;
			height: 30px;
			margin-bottom: 0;
		}
		.react-calendar__month-view__weekdays__weekday {
			text-transform: none;
		}
		.react-calendar__navigation__label:disabled {
			background-color: transparent;
			color: white;
			font-weight: bold;
		}
		.react-calendar__tile {
			padding: 2px;
			margin: #{$__margin-default}px;
			max-width: calc(100%/7 - #{2*$__margin-default}px) !important;
			@include ui-state-default;
			&:hover {
				@include ui-state-hover;
			}
			&:active {
				@include ui-state-active;
			}
			&:disabled {
				@include ui-state-disabled;
			}
			&--active {
				@include ui-state-active;
			}
			&--now {
				@include ui-state-highlight;
			}
		}
	}