//TODO: replace this styles

.freeSeat,
	.bookedSeat,
	.occupiedSeat,
	.changingSeat {
			background-repeat: no-repeat;
			width: 20px;
			cursor: default;
			font-size: 8pt;
	}
	
	.freeSeat {
		background-image: url("/images/bus/DV-CntLvr1.gif");
		
		&:hover {
					color: #1faece;
			}
	}
	
	.bookedSeat {
			background-image: url("/images/bus/DV-CntRsr.gif");
			color: White !important;
		cursor: pointer;
		
		&:hover {
					color: Yellow;
			}
	}
	
	.occupiedSeat {
			background-image: url("/images/bus/DV-CntOcd.gif");
	}
	
	.changingSeat {
			background-image: url("/images/bus/DV-CntChange.gif");
			cursor: pointer;
	}	
	.busTripDestination {
			font-weight: bold;
			color: Red;
	}
	
	.busTableContainer {
			float: left;
	}
	
	.busPassengerContainer {
			vertical-align: middle;
	}
	
	
	.tabelaAutocarros {
			background-color: #e8ecf0;
			border: 3px solid #4b8a9c;
			-webkit-border-top-right-radius: 20px;
			-webkit-border-bottom-right-radius: 20px;
			-moz-border-radius-topright: 20px;
			-moz-border-radius-bottomright: 20px;
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;
	}
	
	#mainPanelTable {
			tr {
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					#infoBoxResponsive {
							flex:1;
							width:100%;
	
							table {
									width: 100%;
	
							}
					}
					#infoBox {
							flex:1;
	
							table {
									width: 100%;
							}
					}
			}
	}
	
	.oldFix{
			display:flex;
	}
	.floatCotainer{
			width:500px;
	}
	
	.invoicPostalCode2Cell {
			width: 70px;
			min-width: 60px;
	}

		
	.formLabel
	{
			font-weight:bold;    
	}

	.formInputLabel {
			text-align:right;
	}

	#companyDataForm input[type=text], #groupDataForm input[type=text], #pointOfSaleDataForm input[type=text]
	{
			min-width: 95%;
	}

	#companyDataForm, #groupDataForm, #pointOfSaleDataForm {
		min-width: 100%;	
	}

	.NewMsgHolder {
    margin-top:5px;
}

	.textwrapper {
			padding:3px;
	}

	#freeTextMsg {
			width:99%;
	}

	.messageInteractionArea {
			padding-top: 15px;
			display: block;    
	}

	#SendMessage {
			float:right;
			margin-right:2px;
	}

	#UserMsgHolder {
			margin-top:15px;
	}

	.MsgHolderTitle {   
			float: left;
	}

	.userMsgUpdateBto {
			float:right;
			width:18px;
			cursor:pointer;
	}

	#MessageHistoryHolder {
			width:99.5%;
			padding-top:5px;  
	}

	.Messages {
			width:99.5%;  
			float:left;
	}

	.messageHistoryPanel {
			height:60%;    
	}

	.MessageHolder img {
			width:13px;
	}

	.MessageHolder {
			float:left;
			width:100%;
			min-height:18px;
	}

	.MessageTime {
			width:23%; 
			float:left;
	}

	.MessageBody {
			width:75%; 
			float:left;
	}

	.MessageReceived {
			color:#009b21;
	}

	.Receiver {
			background-color:#CCCCCC;
	}

	.Sender {
			background-color:#EBEBE3;
	}

	.MessageDate {
			background-color:#ccc;
			float:left;
			width:100%;
	}
	.SALES {
		.panel {
			height: 100%;
		}
	}

	.listItemCurrentOption {
			color: red;
	}

	#liquidationNotesTableHolder {
			overflow:auto;
	}

	#liquidationNotesTableHolder img
	{
			cursor:pointer;    
	}

	.SalesAccDetailsForm {
			overflow-y:auto;
			float:left;   
			width:99%;
			max-height:453px !important;
	}

	.SalesAccDetailsFormOperator {
			overflow-y:auto;
			float:left;   
			width:99%;
			max-height:400px !important;
			min-height:400px !important;
	}

	.CurrLiquidationSalesInfoPane
	{
			float:left;
			margin-top: 10px;    
	}

	#CurrLiquidationSalesTotal
	{
			width:40px;    
	}

	.CompaniesList
	{
			list-style:none;
			padding:5px;
			white-space:normal;
	}

	.CompaniesList td
	{    
			text-align:left !important;
	}

	img {
		vertical-align: bottom;
	}
	
	a img {
		border: none;
	}
	a:link {
		text-decoration: none;
		color: #1faece;
	}
	a:visited {
		text-decoration: none;
		color: #1faece;
	}
	a:hover {
		text-decoration: none;
		color: #1faece;
	}
	
	#Filter {
		float: left;
		margin-left: 10px;
		margin-top: 10px;
		font-weight: bold;
	}
	
	
	.fieldError {
		border: 1px solid getColor("error-text");
	}
	
	.okMessage
	 {
		text-align: center;
		color: green;
	}
	
	#mainPanelTable .combobox {
		min-width: 200px;
	}
	
	.separador {
		font-size: 12px;
		color: #99cc00;
		font-weight: bold;
		text-align: left;
	}
	
	.txt_unreadMessages {
		font-size: 13px;
		color: #fff;
		text-align: left;
		margin-right: 12px;
		padding-top: 2px;
	}
	
	.txt_vermelho {
		font-size: 12px;
		color: #cc0000;
		text-align: left;
		line-height: 16px;
	}
	
	.txt_roxo {
		font-size: 12px;
		color: #660066;
		text-align: left;
		line-height: 16px;
	}
	
	.txt_azul {
		font-size: 12px;
		color: #1faece;
		text-align: left;
		line-height: 16px;
	}
	
	.tit_normal {
		font-size: 13px;
		text-align: left;
		font-weight: bold;
		line-height: 18px;
	}
	
	.tit_vermelho {
		font-size: 13px;
		color: #cc0000;
		text-align: left;
		font-weight: bold;
		line-height: 18px;
	}
	
	.tit_roxo {
		font-size: 13px;
		color: #660066;
		text-align: left;
		font-weight: bold;
		line-height: 18px;
	}
	
	.tit_azul {
		font-size: 13px;
		color: #1faece;
		text-align: left;
		font-weight: bold;
		line-height: 18px;
	}
	
	.no_display {
		display: none !important;
	}
	
	span .tab_menu {
		border: none;
	}
	
	.tab_menu {
		font-size: 1em;
		padding: 0.2em 0.4em;
		cursor: default;
		border: 1px #1faece solid;
		border-bottom: none;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
	span .tab_menu_selected {
		border: none;
	}
	
	.tab_menu_selected {
		color: #1faece;
		border: 1px #1faece solid;
		border-bottom: 1px #333 solid;
		background-color: #333;
	}
	
	
	.colored-old {
		background-color: #e8ecf0;
	}
	
	.horario_disabled {
		opacity: 0.6;
	}
	
	.client_info_container {
		margin-left: 5px;
		margin-bottom: 5px;
		border: solid 1px #0e5163;
		border-radius: 5px;
		background-color: #f9f9f9;
		margin-left: 10px;
		width: 99%;
	}
	
	.titleBar_button {
		cursor: pointer;
		cursor: hand;
	}
	
	.titleBar_button:not([disabled]) img {
		box-shadow: 0px 0px 2px #fff;
	}
	
	.titleBar_button:hover:not([disabled]) img {
		box-shadow: 0px 0px 7px #fff;
	}
	
	.tripTitle {
		color: #074252;
		font-weight: bold;
	}
	
	.tripOrigin {
		color: #3f3c3c;
		font-weight: bold;
	}
	
	.tripDestination {
		color: #d82727;
		font-weight: bold;
	}
	
	.tripDistance {
		font-weight: bold;
	}
	
	#tabSeats {
		overflow-y: auto;
	}
	
	.agentInfoBox,
	.innerContentBox,
	.contentBox {
		padding: 3px;
		margin-bottom: 3px;
		margin-left: 3px;
		border: 1px solid #90c5d0;
		border-radius: 5px;
	}
	.agentInfoBox {
		max-height: 500px;
		min-height: 110px;
	}
	
	
	.errorPanel {
		font-weight: bolder;
		color: red;
		font-size: larger;
		text-align: center;
		vertical-align: middle;
	}
	.tableSeparator td {
		border-top: 1px solid #1faece;
	}
	
	td.filler {
		width: 15px;
		max-width: 15px;
		min-width: 15px;
	}
	td.separator {
		background-color: #1faece;
	}
	.ui-autocomplete {
		max-height: 300px;
		overflow-y: auto;
		overflow-x: hidden;
		padding-right: 20px;
	}

	.mensagens {
		float: right;
		margin-right: 10px;
		height: 20px;
		text-align: right;
		width: 21%;
		z-index: 500;
		position: relative;
		margin-top: 8px;
		color: White;
	}