$env-is-site: false;
$env-is-sove: true;
$is-development: false;
$is-production: true;
@import "../../styles/global/functions/randomColor.scss";

@function randomColor() {
    @return rgba(random(255),random(255),random(255),1);
}

@import "../../styles/global/mixins/env.scss";
.ui-autocomplete {
	position: absolute;
	cursor: default;
}

* html .ui-autocomplete {
	width: 1px;
	height: 300px;
}
@mixin ui-button {
	display: inline-block;
	position: relative;
	padding: 0;
	margin-right: .1em;
	text-decoration: none !important;
	cursor: pointer;
	text-align: center;
	zoom: 1;
	overflow: visible;

	.ui-button-text {
		display: block;
		line-height: 1.4;
	}
}

@mixin ui-button-icon-only {
	width: 2.2em;
	width: 3.4em;

	button & {
		width: 2.4em;
	}

	.ui-icon {
		left: 50%;
		margin-left: -8px;
	}
}


@mixin ui-button-text-only { 
	.ui-button-text {
		padding: .4em 1em;
	}

	button & {
		width: 3.7em;
	}
}

@mixin ui-button-text {
	.ui-button-icon-only &,
	.ui-button-icons-only & {
		padding: .4em;
		text-indent: -9999999px;
	}
		
	.ui-button-text-icon-primary &,
	.ui-button-text-icons & {
		padding: .4em 1em .4em 2.1em;
	}
		
	.ui-button-text-icon-secondary &,
	.ui-button-text-icons & {
		padding: .4em 2.1em .4em 1em;
	}
		
	.ui-button-text-icons & {
		padding-left: 2.1em;
		padding-right: 2.1em;
	}
}

@mixin ui-datepicker {
	width: 17em;
	padding: .2em .2em 0;
	display: none;

	&.ui-datepicker-multi {
		width: auto;
	}
}

@mixin ui-datepicker-multi {
	.ui-datepicker-group {
		float: left;
		table {
			width: 95%;
			margin: 0 auto .4em;
		}
	}
	.ui-datepicker-group-last .ui-datepicker-header {
		border-left-width: 0;
	}
	.ui-datepicker-group-middle .ui-datepicker-header {
		border-left-width: 0;
	}
	.ui-datepicker-buttonpane {
		clear: left;
	}
}

@mixin ui-datepicker-multi-2 {
	.ui-datepicker-group {
		width: 50%;
	}
}


@mixin ui-datepicker-multi-3 {
	.ui-datepicker-group {
		width: 33.3%;
	}
}


@mixin ui-datepicker-multi-4 {
	.ui-datepicker-group {
		width: 25%;
	}
}

@mixin ui-datepicker-row-break {
	clear: both;
	width: 100%;
	font-size: 0em;
}

@mixin ui-datepicker-rtl {
	direction: rtl;
	.ui-datepicker-prev {
		right: 2px;
		left: auto;
	}
	.ui-datepicker-next {
		left: 2px;
		right: auto;
	}
	.ui-datepicker-prev:hover {
		right: 1px;
		left: auto;
	}
	.ui-datepicker-next:hover {
		left: 1px;
		right: auto;
	}
	.ui-datepicker-buttonpane {
		clear: right;
		button {
			float: left;
		}
		button.ui-datepicker-current {
			float: right;
		}
	}
	.ui-datepicker-group {
		float: right;
	}
	.ui-datepicker-group-last .ui-datepicker-header {
		border-right-width: 0;
		border-left-width: 1px;
	}
	.ui-datepicker-group-middle .ui-datepicker-header {
		border-right-width: 0;
		border-left-width: 1px;
	}
}

@mixin ui-datepicker-cover {
	display: none;
	display: block;
	position: absolute;
	z-index: -1;
	filter: mask();
	top: -4px;
	left: -4px;
	width: 200px;
	height: 200px;
}

@mixin ui-helper-hidden {
	display: none;
}

@mixin ui-helper-hidden-accessible {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
}

@mixin ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}

@mixin ui-helper-clearfix {
	zoom: 1;
	&:after {
		clear: both;
	}
	&:after,
	&:before {
		content: "";
		display: table;
	}
}

@mixin ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0);
}


/* states and images */
@mixin ui-icon {
	width: 16px;
	height: 16px;
	background-image: url("/images/ui-icons_469bdd_256x240.png");
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;

	.ui-button-icon-only &,
	.ui-button-text-icon-primary &,
	.ui-button-text-icon-secondary &,
	.ui-button-text-icons &,
	.ui-button-icons-only & {
		position: absolute;
		top: 50%;
		margin-top: -8px;
	}
}

/* positioning */
.ui-icon-carat-1-n { background-position: 0 0; }
.ui-icon-carat-1-ne { background-position: -16px 0; }
.ui-icon-carat-1-e { background-position: -32px 0; }
.ui-icon-carat-1-se { background-position: -48px 0; }
.ui-icon-carat-1-s { background-position: -64px 0; }
.ui-icon-carat-1-sw { background-position: -80px 0; }
.ui-icon-carat-1-w { background-position: -96px 0; }
.ui-icon-carat-1-nw { background-position: -112px 0; }
.ui-icon-carat-2-n-s { background-position: -128px 0; }
.ui-icon-carat-2-e-w { background-position: -144px 0; }
.ui-icon-triangle-1-n { background-position: 0 -16px; }
.ui-icon-triangle-1-ne { background-position: -16px -16px; }
.ui-icon-triangle-1-e { background-position: -32px -16px; }
.ui-icon-triangle-1-se { background-position: -48px -16px; }
.ui-icon-triangle-1-s { background-position: -64px -16px; }
.ui-icon-triangle-1-sw { background-position: -80px -16px; }
.ui-icon-triangle-1-w { background-position: -96px -16px; }
.ui-icon-triangle-1-nw { background-position: -112px -16px; }
.ui-icon-triangle-2-n-s { background-position: -128px -16px; }
.ui-icon-triangle-2-e-w { background-position: -144px -16px; }
.ui-icon-arrow-1-n { background-position: 0 -32px; }
.ui-icon-arrow-1-ne { background-position: -16px -32px; }
.ui-icon-arrow-1-e { background-position: -32px -32px; }
.ui-icon-arrow-1-se { background-position: -48px -32px; }
.ui-icon-arrow-1-s { background-position: -64px -32px; }
.ui-icon-arrow-1-sw { background-position: -80px -32px; }
.ui-icon-arrow-1-w { background-position: -96px -32px; }
.ui-icon-arrow-1-nw { background-position: -112px -32px; }
.ui-icon-arrow-2-n-s { background-position: -128px -32px; }
.ui-icon-arrow-2-ne-sw { background-position: -144px -32px; }
.ui-icon-arrow-2-e-w { background-position: -160px -32px; }
.ui-icon-arrow-2-se-nw { background-position: -176px -32px; }
.ui-icon-arrowstop-1-n { background-position: -192px -32px; }
.ui-icon-arrowstop-1-e { background-position: -208px -32px; }
.ui-icon-arrowstop-1-s { background-position: -224px -32px; }
.ui-icon-arrowstop-1-w { background-position: -240px -32px; }
.ui-icon-arrowthick-1-n { background-position: 0 -48px; }
.ui-icon-arrowthick-1-ne { background-position: -16px -48px; }
.ui-icon-arrowthick-1-e { background-position: -32px -48px; }
.ui-icon-arrowthick-1-se { background-position: -48px -48px; }
.ui-icon-arrowthick-1-s { background-position: -64px -48px; }
.ui-icon-arrowthick-1-sw { background-position: -80px -48px; }
.ui-icon-arrowthick-1-w { background-position: -96px -48px; }
.ui-icon-arrowthick-1-nw { background-position: -112px -48px; }
.ui-icon-arrowthick-2-n-s { background-position: -128px -48px; }
.ui-icon-arrowthick-2-ne-sw { background-position: -144px -48px; }
.ui-icon-arrowthick-2-e-w { background-position: -160px -48px; }
.ui-icon-arrowthick-2-se-nw { background-position: -176px -48px; }
.ui-icon-arrowthickstop-1-n { background-position: -192px -48px; }
.ui-icon-arrowthickstop-1-e { background-position: -208px -48px; }
.ui-icon-arrowthickstop-1-s { background-position: -224px -48px; }
.ui-icon-arrowthickstop-1-w { background-position: -240px -48px; }
.ui-icon-arrowreturnthick-1-w { background-position: 0 -64px; }
.ui-icon-arrowreturnthick-1-n { background-position: -16px -64px; }
.ui-icon-arrowreturnthick-1-e { background-position: -32px -64px; }
.ui-icon-arrowreturnthick-1-s { background-position: -48px -64px; }
.ui-icon-arrowreturn-1-w { background-position: -64px -64px; }
.ui-icon-arrowreturn-1-n { background-position: -80px -64px; }
.ui-icon-arrowreturn-1-e { background-position: -96px -64px; }
.ui-icon-arrowreturn-1-s { background-position: -112px -64px; }
.ui-icon-arrowrefresh-1-w { background-position: -128px -64px; }
.ui-icon-arrowrefresh-1-n { background-position: -144px -64px; }
.ui-icon-arrowrefresh-1-e { background-position: -160px -64px; }
.ui-icon-arrowrefresh-1-s { background-position: -176px -64px; }
.ui-icon-arrow-4 { background-position: 0 -80px; }
.ui-icon-arrow-4-diag { background-position: -16px -80px; }
.ui-icon-extlink { background-position: -32px -80px; }
.ui-icon-newwin { background-position: -48px -80px; }
.ui-icon-refresh { background-position: -64px -80px; }
.ui-icon-shuffle { background-position: -80px -80px; }
.ui-icon-transfer-e-w { background-position: -96px -80px; }
.ui-icon-transferthick-e-w { background-position: -112px -80px; }
.ui-icon-folder-collapsed { background-position: 0 -96px; }
.ui-icon-folder-open { background-position: -16px -96px; }
.ui-icon-document { background-position: -32px -96px; }
.ui-icon-document-b { background-position: -48px -96px; }
.ui-icon-note { background-position: -64px -96px; }
.ui-icon-mail-closed { background-position: -80px -96px; }
.ui-icon-mail-open { background-position: -96px -96px; }
.ui-icon-suitcase { background-position: -112px -96px; }
.ui-icon-comment { background-position: -128px -96px; }
.ui-icon-person { background-position: -144px -96px; }
.ui-icon-print { background-position: -160px -96px; }
.ui-icon-trash { background-position: -176px -96px; }
.ui-icon-locked { background-position: -192px -96px; }
.ui-icon-unlocked { background-position: -208px -96px; }
.ui-icon-bookmark { background-position: -224px -96px; }
.ui-icon-tag { background-position: -240px -96px; }
.ui-icon-home { background-position: 0 -112px; }
.ui-icon-flag { background-position: -16px -112px; }
.ui-icon-calendar { background-position: -32px -112px; }
.ui-icon-cart { background-position: -48px -112px; }
.ui-icon-pencil { background-position: -64px -112px; }
.ui-icon-clock { background-position: -80px -112px; }
.ui-icon-disk { background-position: -96px -112px; }
.ui-icon-calculator { background-position: -112px -112px; }
.ui-icon-zoomin { background-position: -128px -112px; }
.ui-icon-zoomout { background-position: -144px -112px; }
.ui-icon-search { background-position: -160px -112px; }
.ui-icon-wrench { background-position: -176px -112px; }
.ui-icon-gear { background-position: -192px -112px; }
.ui-icon-heart { background-position: -208px -112px; }
.ui-icon-star { background-position: -224px -112px; }
.ui-icon-link { background-position: -240px -112px; }
.ui-icon-cancel { background-position: 0 -128px; }
.ui-icon-plus { background-position: -16px -128px; }
.ui-icon-plusthick { background-position: -32px -128px; }
.ui-icon-minus { background-position: -48px -128px; }
.ui-icon-minusthick { background-position: -64px -128px; }
.ui-icon-close { background-position: -80px -128px; }
.ui-icon-closethick { background-position: -96px -128px; }
.ui-icon-key { background-position: -112px -128px; }
.ui-icon-lightbulb { background-position: -128px -128px; }
.ui-icon-scissors { background-position: -144px -128px; }
.ui-icon-clipboard { background-position: -160px -128px; }
.ui-icon-copy { background-position: -176px -128px; }
.ui-icon-contact { background-position: -192px -128px; }
.ui-icon-image { background-position: -208px -128px; }
.ui-icon-video { background-position: -224px -128px; }
.ui-icon-script { background-position: -240px -128px; }
.ui-icon-alert { background-position: 0 -144px; }
.ui-icon-info { background-position: -16px -144px; }
.ui-icon-notice { background-position: -32px -144px; }
.ui-icon-help { background-position: -48px -144px; }
.ui-icon-check { background-position: -64px -144px; }
.ui-icon-bullet { background-position: -80px -144px; }
.ui-icon-radio-off { background-position: -96px -144px; }
.ui-icon-radio-on { background-position: -112px -144px; }
.ui-icon-pin-w { background-position: -128px -144px; }
.ui-icon-pin-s { background-position: -144px -144px; }
.ui-icon-play { background-position: 0 -160px; }
.ui-icon-pause { background-position: -16px -160px; }
.ui-icon-seek-next { background-position: -32px -160px; }
.ui-icon-seek-prev { background-position: -48px -160px; }
.ui-icon-seek-end { background-position: -64px -160px; }
.ui-icon-seek-start { background-position: -80px -160px; }
/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first { background-position: -80px -160px; }
.ui-icon-stop { background-position: -96px -160px; }
.ui-icon-eject { background-position: -112px -160px; }
.ui-icon-volume-off { background-position: -128px -160px; }
.ui-icon-volume-on { background-position: -144px -160px; }
.ui-icon-power { background-position: 0 -176px; }
.ui-icon-signal-diag { background-position: -16px -176px; }
.ui-icon-signal { background-position: -32px -176px; }
.ui-icon-battery-0 { background-position: -48px -176px; }
.ui-icon-battery-1 { background-position: -64px -176px; }
.ui-icon-battery-2 { background-position: -80px -176px; }
.ui-icon-battery-3 { background-position: -96px -176px; }
.ui-icon-circle-plus { background-position: 0 -192px; }
.ui-icon-circle-minus { background-position: -16px -192px; }
.ui-icon-circle-close { background-position: -32px -192px; }
.ui-icon-circle-triangle-e { background-position: -48px -192px; }
.ui-icon-circle-triangle-s { background-position: -64px -192px; }
.ui-icon-circle-triangle-w { background-position: -80px -192px; }
.ui-icon-circle-triangle-n { background-position: -96px -192px; }
.ui-icon-circle-arrow-e { background-position: -112px -192px; }
.ui-icon-circle-arrow-s { background-position: -128px -192px; }
.ui-icon-circle-arrow-w { background-position: -144px -192px; }
.ui-icon-circle-arrow-n { background-position: -160px -192px; }
.ui-icon-circle-zoomin { background-position: -176px -192px; }
.ui-icon-circle-zoomout { background-position: -192px -192px; }
.ui-icon-circle-check { background-position: -208px -192px; }
.ui-icon-circlesmall-plus { background-position: 0 -208px; }
.ui-icon-circlesmall-minus { background-position: -16px -208px; }
.ui-icon-circlesmall-close { background-position: -32px -208px; }
.ui-icon-squaresmall-plus { background-position: -48px -208px; }
.ui-icon-squaresmall-minus { background-position: -64px -208px; }
.ui-icon-squaresmall-close { background-position: -80px -208px; }
.ui-icon-grip-dotted-vertical { background-position: 0 -224px; }
.ui-icon-grip-dotted-horizontal { background-position: -16px -224px; }
.ui-icon-grip-solid-vertical { background-position: -32px -224px; }
.ui-icon-grip-solid-horizontal { background-position: -48px -224px; }
.ui-icon-gripsmall-diagonal-se { background-position: -64px -224px; }
.ui-icon-grip-diagonal-se { background-position: -80px -224px; }
@mixin ui-menu {
	list-style: none;
	padding: 2px;
	margin: 0;
	display: block;
	float: left;
	z-index: $z-ui-menu;
	.ui-menu {
		margin-top: -3px;
	}
	.ui-menu-item {
		margin: 0;
		padding: 0;
		zoom: 1;
		float: left;
		clear: left;
		width: 100%;
		border-radius: 3px;


		&:hover {
			border: 1px solid getColor("border-active-color");
			@include background-glass("d0e5f5", 75);
			font-weight: bold;
			color: getColor("link-hover");

			a, div {
				font-weight: normal;
				margin: -1px;
				
				border: 1px solid getColor("border-active-color");
				@include background-glass("d0e5f5", 75);
				font-weight: bold;
				color: getColor("link-hover");
				border-radius: 3px;
			}
		}

		a, div {
			text-decoration: none;
			display: block;
			padding: .2em .4em;
			line-height: 1.5;
			zoom: 1;
						
			&.ui-state-hover,
			&.ui-state-active {
				font-weight: normal;
				margin: -1px;
			}
		}
	}
}

@mixin ui-progressbar {
	height: 2em;
	text-align: left;
	overflow: hidden;
	
	.ui-progressbar-value {
		margin: -1px;
		height: 100%;
	}
}

@mixin ui-resizable {
	position: relative;
}

@mixin ui-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	z-index: $z-ui-resizable-handle;
	display: block;
}

@mixin ui-resizable-disabled {
	.ui-resizable-handle {
		display: none;
	}
}

@mixin ui-resizable-n {
	cursor: n-resize;
	height: 7px;
	width: 100%;
	top: -5px;
	left: 0;
}

@mixin ui-resizable-s {
	cursor: s-resize;
	height: 7px;
	width: 100%;
	bottom: -5px;
	left: 0;
}

@mixin ui-resizable-e {
	cursor: e-resize;
	width: 7px;
	right: -5px;
	top: 0;
	height: 100%;
}

@mixin ui-resizable-w {
	cursor: w-resize;
	width: 7px;
	left: -5px;
	top: 0;
	height: 100%;
}

@mixin ui-resizable-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px;
}

@mixin ui-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	bottom: -5px;
}

@mixin ui-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	top: -5px;
}

@mixin ui-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	right: -5px;
	top: -5px;
}
@mixin ui-selectable-helper {
	position: absolute;
	z-index: $z-ui-selectable-helper;
	border:1px dotted black;
}
@mixin ui-slider {
	position: relative;
	text-align: left;

	.ui-slider-handle {
		position: absolute;
		z-index: 2;
		width: 1.2em;
		height: 1.2em;
		cursor: default;
	}

	.ui-slider-range {
		position: absolute;
		z-index: 1;
		font-size: .7em;
		display: block;
		border: 0;
		background-position: 0 0;
	}
}

@mixin ui-slider-horizontal {
	height: .8em;

	.ui-slider-handle {
		top: -.3em;
		margin-left: -.6em;
	}

	.ui-slider-range {
		top: 0;
		height: 100%;
	}

	.ui-slider-range-min {
		left: 0;
	}

	.ui-slider-range-max {
		right: 0;
	}
}

@mixin ui-slider-vertical {
	width: .8em;
	height: 100px;

	.ui-slider-handle {
		left: -.3em;
		margin-left: 0;
		margin-bottom: -.6em;
	}

	.ui-slider-range {
		left: 0;
		width: 100%;
	}

	.ui-slider-range-min {
		bottom: 0;
	}

	.ui-slider-range-max {
		top: 0;
	}
}
@mixin __ui-state-hoverfocus {
	&:not(.disabled):not(.active),
	.ui-widget-content &,
	.ui-widget-header & {
		border: 1px solid getColor("border-active-color");
		@include background-glass("d0e5f5", 75);
		font-weight: bold;
		color: getColor("link-hover");
	}

	.ui-icon {
		background-image: url("/images/ui-icons_217bc0_256x240.png");
	}
}

@mixin __ui-state-alllinks() {
	& > a,
	& > a:link,
	& > a:visited,
	& > .tab-header-text {
		text-decoration: none;
		@content;
	}
}

@mixin ui-state-default {
	@include __ui-state-alllinks {
		color: getColor("link-default-color");
	}

	&,
	.ui-widget-content &,
	.ui-widget-header & {
		border: 1px solid getColor("border-default-color");
		@include background-glass("dfeffc", 85);
		font-weight: bold;
		color: getColor("link-default-color");
	}

	.ui-icon {
		background-image: url("/images/ui-icons_6da8d5_256x240.png");
	}
}

@mixin ui-state-active {
	@include __ui-state-alllinks {
		color: getColor("link-active-color");
	}

	&,
	.ui-widget-content &,
	.ui-widget-header & {
		border: 1px solid getColor("border-active-color");
		background-color: #f5f8f9;
		background: #f5f8f9 url("/images/ui-bg_inset-hard_100_f5f8f9_1x100.png") 50% 50% repeat-x;
		font-weight: bold;
		color: #0e5163;
	}

	.ui-icon {
		background-image: url("/images/ui-icons_f9bd01_256x240.png");
	}
}

@mixin ui-state-hover {
	@include __ui-state-hoverfocus;
	@include __ui-state-alllinks {
		color: getColor("link-hover");
	}
}

@mixin ui-state-focus {
	@include __ui-state-hoverfocus;
	
}

@mixin ui-state-disabled {
	cursor: default !important;

		
	&,
	.ui-widget-content &,
	.ui-widget-header & {
		opacity: .35;
		filter: Alpha(Opacity=35);
		background-image: none;
	}
}

@mixin ui-state-highlight {
	&,
	.ui-widget-content &,
	.ui-widget-header & {
		border: 1px solid #89b931;
		@include background-flat("98ca3c", 55);
		color: #363636;
		a {
			color: #363636;
		}
	}

	.ui-icon {
		background-image: url("/images/ui-icons_2e83ff_256x240.png");
	}
}

@mixin ui-state-error {
	&,
	.ui-widget-content &,
	.ui-widget-header & {
		border: 1px solid #cd0a0a;
		@include background-glass("fef1ec", 95);
		color: #cd0a0a;

		a {
			color: #cd0a0a;
		}
	}

	.ui-icon {
		background-image: url("/images/ui-icons_cd0a0a_256x240.png");
	}
}

@mixin ui-state-error-text {
	&,
	.ui-widget-content &,
	.ui-widget-header & {
		color: #cd0a0a;
	}

	.ui-icon {
		background-image: url("/images/ui-icons_cd0a0a_256x240.png");
	}
}

@mixin ui-priority-primary {
	&,
	.ui-widget-content &,
	.ui-widget-header & {
		font-weight: bold;
	}
}

@mixin ui-priority-secondary {
	&,
	.ui-widget-content &,
	.ui-widget-header & {
		opacity: .7;
		filter: Alpha(Opacity=70);
		font-weight: normal;
	}
}
@mixin ui-tabs-nav {
	&.ui-widget-header {
		background-color: transparent;
		background-image: none;
		border-style: none;
	}
}

@mixin ui-tabs-panel {
    border-width: 1px !important;
    overflow: hidden;
    padding: 0.3em 0.1em !important;
}

@mixin ui-tabs {
    border-width: 0 !important;
	padding: 0.3em 0.1em !important;
	position: relative;
	padding: .2em;
	zoom: 1;

	&.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-selected a {
		cursor: pointer;
	}
		
	.ui-tabs-nav {
		margin: 0;
		padding: .2em .2em 0;
		li {
			list-style: none;
			float: left;
			position: relative;
			top: 1px;
			margin: 0 .2em 1px 0;
			border-bottom: 0 !important;
			padding: 0;
			white-space: nowrap;
			margin-left: -2px;

			&.ui-tabs-selected {
				margin-bottom: 0;
				padding-bottom: 1px;
			}

			&.ui-tabs-selected a,
			&.ui-state-disabled a,
			&.ui-state-processing a {
				cursor: text;
			}

			a {
				cursor: pointer;
				float: left;
				padding: .5em 1em;
				text-decoration: none;
			}
		}
	}
	
	.ui-tabs-panel {
		display: block; border-width: 0; padding: 1em 1.4em; background: none; 
	}
	.ui-tabs-hide {
		display: none !important;
	}
}


@mixin ui-tooltip {
	position: absolute;
	z-index: $z-ui-tooltip;
	display: block;
	visibility: visible;
	margin: 0;
	padding: 0;
	font-size: 11px;
	opacity: 0;

	box-shadow: none;

	&.in {
		opacity: 1;
	}
	&.top {
		margin-top: -2px;

		.tooltip-arrow {
			bottom: 0;
			left: 50%;
			margin-left: -5px;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #000000;
		}
	}
	&.right {
		margin-left: 2px;

		.tooltip-arrow {
			top: 50%;
			left: 0;
			margin-top: -5px;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-right: 5px solid #000000;
		}
	}
	&.bottom {
		margin-top: 2px;

		.tooltip-arrow {
			top: 0;
			left: 50%;
			margin-left: -5px;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid #000000;
		}
	}
	&.left {
		margin-left: -2px;

		.tooltip-arrow {
			top: 50%;
			right: 0;
			margin-top: -5px;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-left: 5px solid #000000;
		}
	}

	.ui-tooltip-content,
	.tooltip-inner {
		margin: 0;
		max-width: 200px;
		padding: 3px 8px;
		color: #ffffff;
		text-align: center;
		text-decoration: none;
		background-color: #000000;
		border-radius: 4px;
	}
	&.ui-widget-shadow {
		padding: 0;
		opacity: 1;

		&:after {
			content: "";
			bottom: 0;
			left: 50%;
			top: 20px;
			margin-left: -5px;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #000000;

			position: absolute;
			width: 0;
			height: 0;
		}
	}
}
@mixin ui-widget {
	font-size: $font-size-13;
	.ui-widget {
		font-size: 1em;
	}

	input,
	select,
	textarea,
	button {
		font-size: 1em;
	}
		
	:active {
		outline: none;
	}
}

@mixin ui-widget-content {
	border: 1px solid getColor("border-active-color");
	background: #fcfdfd url("/images/ui-bg_inset-hard_100_fcfdfd_1x100.png") 50% bottom repeat-x;
	color: #222222;

	a {
		color: #222222;
	}
	.ui-icon {
		background-image: url("/images/ui-icons_469bdd_256x240.png");
	}
}

@mixin ui-widget-header {
	border: 1px solid getColor("primary");
	color: #ffffff;
	font-weight: bold;
	background-color: getColor("primary");
	@include linear-gradient(getColor("primary"), getColor("primary-hover"));

	a {
		color: #ffffff;
	}

	.ui-icon {
		background-image: url("/images/ui-icons_d8e7f3_256x240.png");
	}
}

@mixin ui-widget-shadow {
	margin: -8px 0 0 -8px;
	padding: 8px;
	@include background-flat("aaaaaa", 0);
	opacity: .30;
	filter: Alpha(Opacity=30);
	border-radius: 8px;
}
@mixin background-glass($color, $opacity) {
	background-color: #{"#"+$color};
	background: #{"#"+$color} url("/images/ui-bg_glass_#{$opacity}_#{$color}_1x400.png") 50% 50% repeat-x;
}

@mixin background-flat($color, $opacity) {
	background-color: #{"#"+$color};
	background: #{"#"+$color} url("/images/ui-bg_flat_#{$opacity}_#{$color}_40x100.png") 50% 50% repeat-x;
}

@mixin border-top-radius($size) {
	border-top-left-radius: $size;
	border-top-right-radius: $size;
}

@mixin border-bottom-radius($size) {
	border-bottom-left-radius: $size;
	border-bottom-right-radius: $size;
}

@mixin border-left-radius($size) {
	border-top-left-radius: $size;
	border-bottom-left-radius: $size;
}

@mixin border-right-radius($size) {
	border-top-right-radius: $size;
	border-bottom-right-radius: $size;
}

@mixin border-all-radius($size) {
	@include border-top-radius($size);
	@include border-bottom-radius($size);
}

@mixin if-dev {@if($is-development) {@content;}}
@mixin if-debug {@if($is-development) {@content;}}
@mixin if-development {@if($is-development) {@content;}}
@mixin if-prod {@if($is-production) {@content;}}
@mixin if-production {@if($is-production) {@content;}}
@mixin field-ratio($ratio) {
	$sum: $ratio+1;
	&,
	.field {
		.field-label {
			width: 100% * ($ratio / $sum);
		}
		.field-input {
			width: 100% * (1 / $sum);
		}
	}
}

$isdebug: false;
$debugColorDefined: aqua;
$debugColorNotDefined: red;
$fontSizeWhiteList: (12,14,16,18,20,21,22,24,28,30,35,36);

@mixin fontstyle($params, $name: false) {
	@include fontstyle-font-color($params);
	$fw: fontstyle-font-weight($params);
	$fs: fontstyle-font-size($params);
	@if($fs!=0) {
		@if(index($fontSizeWhiteList, $fs) == null) {
			@error "Font size "+$fs+" not in list";
		}
		font-size: #{$fs}px;
	}
	font-weight: $fw;
	@if($isdebug) {
		&:after {
			@if($name!=false) {
				content: $name;
				color: $debugColorDefined;
			}
			@else {
				content: "#{$fs} #{$fw}";
				color: $debugColorNotDefined;
			}
			position: absolute;
			right: -12px;
			top: -12px;
		}
	}
}

@mixin fontstyle-font-color($params) {
	//color: randomColor();
	@if(str-index($params, "white") != null) {
		color: white;
	}
	@else if(str-index($params, "black") != null) {
		color: black;
	}
	@else if(str-index($params, "gray") != null) {
		color: gray;
	}
	@else if(str-index($params, "grey") != null) {
		color: gray;
	}
	@else if(str-index($params, "primary") != null) {
		color: getColor("primary");
		&:hover {
			color: getColor("primary-text");
		}
	}
}

@function fontstyle-font-size($params) {
	$value: string-to-number($params);
	@return $value;
}

@function fontstyle-font-weight($params) {
	@if(str-index($params, "seminormal") != null) { //
		@return  500;
	}
	@else if(str-index($params, "semibold") != null) {
		@return 600;
	}
	@else if(str-index($params, "semi") != null) {
		@return 600;
	}
	@else if(str-index($params, "normal") != null) {
		@return normal; //400
	}
	
	@else if(str-index($params, "bold") != null) {
		@return bold; //700
	}
	@return normal;
}

@function string-to-number($string) {
	$strings: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
	$numbers:  0   1   2   3   4   5   6   7   8   9;
	$result: 0;
	@for $i from 1 through str-length($string) {
		$character: str-slice($string, $i, $i);
		$index: index($strings, $character);
		@if not $index {
		  @return $result;
		}
		$number: nth($numbers, $index);
		$result: $result * 10 + $number;
	  }
  
	@return $result;
}
@mixin linear-gradient($start, $end) {
	background-color: $start;
    background: -webkit-gradient(linear, left top, left bottom, from($start), to($end));
    background: -webkit-linear-gradient($start,$end);
    background: -moz-linear-gradient(top, $start, $end);
    background: -ms-linear-gradient($start, $end);
    background: -o-linear-gradient($start, $end);
    background: linear-gradient($start, $end);
    zoom: 1;
}
@import "../../styles/global/mixins/borders.scss";
@import "../../styles/global/mixins/triangle-borders.scss";
@import "../../styles/global/mixins/statuses.scss";
@import "../../styles/global/mixins/gradients.scss";
$xs-min: 0px;
$xs-max: 575px;

$sm-min: $xs-max+1px;
$sm-max: 767px;

$md-min: $sm-max+1px;
$md-max: 991px;

$lg-min: $md-max+1px;
$lg-max: 1199px;

$xl-min: $lg-max+1px;
$xl-max: 575px;

@mixin xs-size() {
  @media screen and (max-width: $xs-max) { @content }
};

@mixin sm-size() {
  @media screen and (min-width: $sm-min) and (max-width: $sm-max) { @content }
};

@mixin ipad-size() {
	@media screen and (min-width: $sm-min) and (max-width: ($sm-max+2)) { @content }
};

@mixin md-size() {
  @media screen and (min-width: $md-min) and (max-width: $md-max) { @content }
};

@mixin lg-size() {
  @media screen and (min-width: $lg-min) and (max-width: $lg-max) { @content }
};

@mixin xl-size() {
  @media screen and (min-width: $xl-min) { @content }
};

@mixin mobile-size() {
  @media screen and (max-width: $sm-max) { @content }
};

@mixin desktop-size() {
  @media screen and (min-width: $md-min) { @content }
};

@mixin sizes($sizes) {
  @each $size in $sizes
  {
    @if($size=="xs") {
      @include xs-size() { @content; }
    }
    @if($size=="sm") {
      @include sm-size() { @content; }
    }
    @if($size=="md") {
      @include md-size() { @content; }
    }
    @if($size=="lg") {
      @include lg-size() { @content; }
    }
    @if($size=="xl") {
      @include xl-size() { @content; }
	  }
	  @if($size=="ipad") {
      @include ipad-size() { @content; }
    }
    @if($size=="desktop") {
      @include desktop-size() { @content; }
    }
    @if($size=="mobile") {
      @include mobile-size() { @content; }
    }
  }
};
@mixin if-disabled {
	&[disabled],
	&.disabled,
	.disabled & {
		@content;
	}
}
@mixin __tab-header-item-default {
	list-style: none;
	float: left;
    position: relative;
    top: 1px;
    margin: 0 .2em 1px 0;
    border-bottom: 0;
	padding: 0.4em 1em;
	text-decoration: none;
    white-space: nowrap;
	margin-left: -2px;
	@include background-glass("dfeffc", 85);
	border: 1px solid getColor("border-default-color");
	border-bottom: none;
	@include border-top-radius(3px);
	
	cursor: pointer;
	& > .tab-header-text {
		color: getColor("link-default-color");
		font-weight: bold;
	}
}

@mixin tab-header-item-inactive {
	@include ui-state-default;
	@include __tab-header-item-default();
	
	cursor: pointer;
	.tab-header-text {
		color: getColor("link-default-color");
		font-weight: bold;
	}
}

@mixin tab-header-item-active {
	@include ui-state-active;
	@include __tab-header-item-default();
	cursor: text;
	top: 0;
	margin-bottom: -1px;
	padding-bottom: 1px;
	
	.tab-header-text {
		color: getColor("link-active-color");
	}
}

@mixin tab-header-item-disabled {
	@include ui-state-disabled;
	@include __tab-header-item-default();
	opacity: 0.35;
	.tab-header-text {
		color: getColor("link-default-color");
	}
}
@mixin __triangle_v($w, $h, $right) {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-left: $w/2 solid transparent;
	border-right: $w/2 solid transparent;
}
@mixin __triangle_h($w, $h, $right) {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-top: $h/2 solid transparent;
	border-bottom: $h/2 solid transparent;
}
@mixin triangle-up($w, $h, $color, $right) {
	@include __triangle_v($w, $h, $right);
	top: calc(50% - #{1.5*$h});
	border-top: $h solid  transparent;
	border-bottom: $h solid $color;
	right: $right;
}

@mixin triangle-down($w, $h, $color, $right) {
	@include __triangle_v($w, $h, $right);
	top: calc(50% - #{0.5*$h});
	border-top: $h solid $color;
	border-bottom: $h solid transparent;
	right: $right;
}

@mixin triangle-left($w, $h, $color, $right) {
	@include __triangle_h($w, $h, $right);
	top: calc(50% - #{0.5*$h});
	border-left: $w solid transparent;
	border-right: $w solid $color;
	right: calc(#{$right});
}

@mixin triangle-right($w, $h, $color, $right) {
	@include __triangle_h($w, $h, $right);
	top: calc(50% - #{0.5*$h});
	border-left: $w solid $color;
	border-right: $w solid transparent;
	right: calc(#{$right} - #{$w});
}

@mixin triangle-test($w, $h, $color, $right) {
	@include triangle-up($w, $h, $color, $right);
}
$field-height-default: 24px;
$check-width-default: 24px;

$field-ratio-default: 1/3;
$field-ratio-login: 1/2;
$field-ratio-dialog: 2/3;
$field-ratio-passengers-invoice: 1/2;
$font-family-helvetica: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-size-13: 13px;
$shadow-ticket-row: 0 2px 4px rgba(0,0,0,0.15);
$shadow-payments-block: 0 2px 4px rgba(0,0,0,0.24);
$shadow-destination-panel: 0 2px 4px rgba(0,0,0,0.5);
$shadow-fronteiras-card: 2px 2px 2px 2px rgba(136, 136, 136, 0.4);


$shadow-buy-container: 0 2px 34px rgba(0, 0, 0, 0.5);

$z-modal: 1000000000000;
$z-bg-drop: -1;
$z-dp-inner: 1;
$z-background: 0;
$z-top-panel: 100;
$z-body: 998;
$z-footer: 999;
$z-gallery: 101;
$z-small-map: 1000;
$z-destination: (
    layer: 10000
);
$z-contact-start: 99;
$z-contact: (
    layer: $z-contact-start - 4,
    container: $z-contact-start - 3,
    align: $z-contact-start - 2 ,
    button: $z-contact-start - 1
);
$z-gototop: 105;
$z-breadcrumb-edit: 1000;
$z-e-select-menu: 100000;
$z-marker-tooltip: 100000;
$z-editable-image-buttons: 999999999999;

$z-session-menu: 100;
$z-menu-links: 100;
$z-ui-selectable-helper: 100;
$z-ui-menu: 130;
$z-loadmask: 100;
$z-loadmask-message: 20001;
$z-ui-tooltip: 1020;
$z-ui-resizable-handle: 99999;
$z-menu-root: 1000;
$z-overlay: 1002;
// @import "../../styleguide/base/index.scss";

@import "../../styleguide/base/getColor.scss";
@import "../../styleguide/base/getFS.scss";
@import "../../styleguide/base/useTheme.scss";

.configuration-users-panel {
	margin: 0;
	& > .content {
		overflow: auto;
		// max-height: 310px;
		margin: 0;

		td {
			min-width: 20px;
		}
	}

	// .see-all {
	// 	display: flex;
	// 	justify-content: flex-end;
	// 	margin-right: 8px;
	// }

}