input.ui-button { padding: .4em 1em; }

.ui-button-icon-primary {
	.ui-button-text-icon-primary &,
	.ui-button-text-icons &,
	.ui-button-icons-only & {
		left: .5em;
	}
}

.ui-button-icon-secondary {
	.ui-button-text-icon-secondary &,
	.ui-button-text-icons &,
	.ui-button-icons-only & {
		right: .5em;
	}
}

.ui-buttonset {
	margin-right: 7px;

	.ui-button {
		margin-left: 0;
		margin-right: -.3em;
	}
}

button.ui-button::-moz-focus-inner {
	border: 0;
	padding: 0;
}