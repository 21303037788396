.footer {
	position: relative;
	background-color: #042831;
	width: 100%;
	height: 20px;
	bottom: 0;
		
	.div_alerts {
		float: left;
		width: 100% !important;
		height: 100%;
		display: block;
		color: White;
		cursor: default;
		margin-top: 3px;
		.alert_title {
			color: red;
			font-weight: bold;
			margin-left: 10px;
			margin-right: 5px;
		}
	
		.alert_msg {
			color: White;
		}
	}
	.footer_loading {
		overflow-x: hidden !important;
		overflow-y: hidden !important;
		opacity: 0.6 !important;
		padding-top: 2px;
		display: none;
			
		.footer_loading_text {
			width: 100%;
			height: 100%;
			text-align: center;
			color: Red;
			font-weight: bold;
			font-size: 1.20em;
		}
	}
}