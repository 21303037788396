.lang-pt,
.lang-en,
.lang-fr,
.lans-es {
    display: none;
}

.lng-pt .lang-pt,
.lng-en .lang-en,
.lng-fr .lang-fr,
.lng-es .lang-es {
    display: block;
}