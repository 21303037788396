.page {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		.body {
			padding: 5px;
			height: 100%;
		}

		&.Login,
		&.PwdReset {
			.body {
				padding: 0;
			}
		}

		& > div:nth-child(2) {
			overflow-y: auto;
			overflow-y: hidden;
			& > .panel {
					max-height: 100%;
					& > .content {
							overflow-y: auto;
					}
					& > #BoardingTableContainer {
							overflow: visible;
					}
			}
			& > div {
					.schedules-panel > .content > .tabs {
							display: flex;
							flex-direction: column;
							height: 100%;
							& > .tabs-layout {
									overflow: auto;
									display: block;
							}
					}
					.panel-main-search > .content > div {
							& > .reservation-search > .tabs > .tabs-nav {
									& > .disabled {
											background: #f3f3f3;
											color: #545454;
									}
									& > .active {
											background: #f5f8f9;
											color: #0e5163;
									}
							}
							& > .reservation-info > div > .flex-column > .flex-end > div > .error-panel > .error {
									padding: 0;
							}
					}
					.panel-reservations > .content > div > div > div > .full-size {
							& > .invalid-invoice > .content > .tabs > .tabs-nav > div:nth-child(1){
									color: red;
							}
							& > .invalid-passengers > .content > .tabs > .tabs-nav > div:nth-child(2){
									color: red;
							}
							& > .invalid-seats > .content > .tabs > .tabs-nav > div:nth-child(3){
									color: red;
							}
							& > #passengers > .content > #tabSeats > .bus-seats-manager > .error-panel > .error {
									padding: 15px 0px 0px 0px;
							}
					}
					.panel-passengers > .content > div > .reservation-info > div > .flex-column > .flex-end > div > .error-panel > .error {
							padding: 0;
					}
			}
		}
}