[class^=btn] {
  padding: 2px 5px;
  min-height: $field-height-default;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #ccc;
  &[disabled] {
    border: 1px solid #7fa7b0;
    background-color: #7fa7b0;
    color: white;
    cursor: default;
    opacity: 0.5;
  }

  &.bigger {
    padding: 10px 20px;
  }

  &.btn-default {
    @include linear-gradient(#ffffff, #d3d3d3);
    border: 1px solid #0d4d5e;
    color: #0d4d5e !important;
    &:hover:not([disabled]) {
      box-shadow: 1px 1px 2px 2px #94c3d0;
    }
  }
  &.btn-primary {
    @include linear-gradient(getColor("primary"), getColor("primary-hover"));
    &:hover:not([disabled]) {
      box-shadow: 1px 1px 2px 2px #94c3d0;
    }
    &:hover {
      @include linear-gradient(getColor("primary-hover"), getColor("primary-hover"));
    }
  }

  &.btn-success {
    border: 1px solid #85a315;
    color: #0d4d5e !important;
    @include linear-gradient(#b2da1d, #85a315);
    &:hover:not([disabled]) {
      box-shadow: 1px 1px 2px 2px #b2da1d;
    }
  }

  &.btn-error {
    border: 1px solid getColor("deep-red-1");
    color: #0d4d5e !important;
    @include linear-gradient(getColor("deep-red-3"), getColor("deep-red-4"));
    &:hover:not([disabled]) {
      box-shadow: 1px 1px 2px 2px getColor("deep-red-2");
    }
  }

  &.btn-style-link {
    background-color: transparent;
    border: none;
    font-size: 13px;
    color: #1faece;
    box-shadow: none;
    outline: none;
    padding: 8px;
    &:hover {
      color: #333;
    }
  
    &:focus:not(:hover) {
      outline: 1px solid #1faece;
    }
  }
}