.table-striped {
	text-align: center;
	table-layout: fixed;
}

.table-striped-grid {
	width: 100%;
	tbody {
		tr:nth-of-type(odd) {
			td:nth-of-type(odd) {
				background-color: rgba(0, 0, 0, 0.05);
			}
			td:nth-of-type(even) {
				background-color: transparent;
			}
		}
		tr:nth-of-type(even) {
			td:nth-of-type(odd) {
				background-color: transparent;
			}
			td:nth-of-type(even) {
				background-color: rgba(0, 0, 0, 0.05);
			}
		}
		
	}
}

.table-primary-header {
	color: getColor("dark-text");
	thead {
		background-color: getColor("primary");
		color: getColor("white");
	}
}