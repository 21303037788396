$block-w: 106px;
$block-h: 54px;
$block-border: 5px;
$block-margin-v: 10px;
$block-margin-h: 10px;
$line-border-style: 2px solid #075768;
$h-line-dist: ($block-h) / 2 + $block-margin-v;
$w-line-dist: ($block-w) / 2 + $block-margin-h;
$background-color: #f9f9f9;

@mixin block-panel {
	margin: $block-margin-v $block-margin-h;
	width: $block-w;
	height: $block-h;
	display: flex;
	
	justify-content: space-between;
}


@mixin h-lines {
	content: "";
	position: absolute;
	border-top: $line-border-style;
	top: 50%;
}