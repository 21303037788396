.tabs-container {
  .tabs-buttons-stack {
    .buttons-list {
      margin-bottom: -1px;
      padding-left: 8px;
    }
  }
  .tabs-view {
    @include ui-widget-content;
    padding: 4px;
  }
}