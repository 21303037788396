@import "./ui-button";
@import "./ui-icon";

.ui-button { @include ui-button; }

.ui-button-icon-only { @include ui-button-icon-only; }
.ui-button-text-only { @include ui-button-text-only; }
.ui-button-text { @include ui-button-text; }

.ui-datepicker { @include ui-datepicker; }
.ui-datepicker-multi { @include ui-datepicker-multi; }
.ui-datepicker-multi-2 { @include ui-datepicker-multi-2; }
.ui-datepicker-multi-3 { @include ui-datepicker-multi-3; }
.ui-datepicker-multi-4 { @include ui-datepicker-multi-4; }
.ui-datepicker-row-break { @include ui-datepicker-row-break; }
.ui-datepicker-rtl { @include ui-datepicker-rtl; }
.ui-datepicker-cover { @include ui-datepicker-cover; }

@mixin ui-draggable {
	.ui-dialog-titlebar {
		cursor: move;
	}
}
.ui-draggable { @include ui-draggable; }

.ui-helper-hidden { @include ui-helper-hidden; }
.ui-helper-hidden-accessible { @include ui-helper-hidden-accessible; }
.ui-helper-reset { @include ui-helper-reset; }
.ui-helper-clearfix { @include ui-helper-clearfix; }
.ui-helper-zfix {  @include ui-helper-zfix; }

.ui-icon { @include ui-icon; }

.ui-menu { @include ui-menu; }

.ui-progressbar { @include ui-progressbar; }

.ui-priority-primary { @include ui-priority-primary; }
.ui-priority-secondary { @include ui-priority-secondary; }

.ui-tabs-nav { @include ui-tabs-nav; }
.ui-tabs-panel { @include ui-tabs-panel; }
.ui-tabs { @include ui-tabs; }

.ui-selectable-helper { @include ui-selectable-helper }

.ui-slider { @include ui-slider; }
.ui-slider-horizontal { @include ui-slider-horizontal; }
.ui-slider-vertical { @include ui-slider-vertical; }

.ui-state-default { @include ui-state-default; }
.ui-state-highlight { @include ui-state-highlight; }
.ui-state-error { @include ui-state-error; }
.ui-state-error-text { @include ui-state-error-text; }
.ui-state-hover { @include ui-state-hover; }
.ui-state-focus { @include ui-state-focus; }
.ui-state-disabled { @include ui-state-disabled; }
.ui-state-active { @include ui-state-active; }

.ui-resizable {	@include ui-resizable; }
.ui-resizable-handle { @include ui-resizable-handle }
.ui-resizable-disabled,
.ui-resizable-autohide { @include ui-resizable-disabled; }
.ui-resizable-n { @include ui-resizable-n }
.ui-resizable-s { @include ui-resizable-s }
.ui-resizable-e { @include ui-resizable-e }
.ui-resizable-w { @include ui-resizable-w }
.ui-resizable-se { @include ui-resizable-se }
.ui-resizable-sw { @include ui-resizable-sw }
.ui-resizable-nw { @include ui-resizable-nw }
.ui-resizable-ne { @include ui-resizable-ne }

.ui-tooltip, .tooltip {
	@include ui-tooltip;
}


.ui-widget-shadow { @include ui-widget-shadow; }
.ui-widget { @include ui-widget; }
.ui-widget-content { @include ui-widget-content; }
.ui-widget-header { @include ui-widget-header; }

.ui-autocomplete {
	position: absolute;
	cursor: default;

	border: 2px solid yellow !important;
}

* html .ui-autocomplete {
	width: 1px;
	height: 300px;
	border: 2px solid red !important;
}

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
	border-top-left-radius: 3px;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
	border-top-right-radius: 3px;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
	border-bottom-left-radius: 3px;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
	border-bottom-right-radius: 3px;
}

.ui-widget-overlay { /*@include ui-widget-overlay; */ border: 5px solid red !important; }