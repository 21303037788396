.relative-xs-down { @include sizes(("xs")) { position: relative; } }
.relative-sm-down { @include sizes(("xs","sm")) { position: relative; } }
.relative-md-down { @include sizes(("xs","sm","md")) { position: relative; } }
.relative-lg-down { @include sizes(("xs","sm","md","lg")) { position: relative; } }
.relative-xl-down { @include sizes(("xs","sm","md","lg","xl")) { position: relative; } }
.relative-xs-up { @include sizes(("xs","sm","md","lg","xl")) { position: relative; } }
.relative-sm-up { @include sizes(("sm","md","lg","xl")) { position: relative; } }
.relative-md-up { @include sizes(("md","lg","xl")) { position: relative; } }
.relative-lg-up { @include sizes(("lg","xl")) { position: relative; } }
.relative-xl-up { @include sizes(("xl")) { position: relative; } }

.absolute-xs-down { @include sizes(("xs")) { position: absolute; } }
.absolute-sm-down { @include sizes(("xs","sm")) { position: absolute; } }
.absolute-md-down { @include sizes(("xs","sm","md")) { position: absolute; } }
.absolute-lg-down { @include sizes(("xs","sm","md","lg")) { position: absolute; } }
.absolute-xl-down { @include sizes(("xs","sm","md","lg","xl")) { position: absolute; } }
.absolute-xs-up { @include sizes(("xs","sm","md","lg","xl")) { position: absolute; } }
.absolute-sm-up { @include sizes(("sm","md","lg","xl")) { position: absolute; } }
.absolute-md-up { @include sizes(("md","lg","xl")) { position: absolute; } }
.absolute-lg-up { @include sizes(("lg","xl")) { position: absolute; } }
.absolute-xl-up { @include sizes(("xl")) { position: absolute; } }

.hidden-xs-down { @include sizes(("xs")) { display: none; } }
.hidden-sm-down { @include sizes(("xs","sm")) { display: none; } }
.hidden-md-down { @include sizes(("xs","sm","md")) { display: none; } }
.hidden-lg-down { @include sizes(("xs","sm","md","lg")) { display: none; } }
.hidden-xl-down { @include sizes(("xs","sm","md","lg","xl")) { display: none; } }
.hidden-xs-up { @include sizes(("xs","sm","md","lg","xl")) { display: none; } }
.hidden-sm-up { @include sizes(("sm","md","lg","xl")) { display: none; } }
.hidden-md-up { @include sizes(("md","lg","xl")) { display: none; } }
.hidden-lg-up { @include sizes(("lg","xl")) { display: none; } }
.hidden-xl-up { @include sizes(("xl")) { display: none; } }