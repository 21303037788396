.field {
		form & {
			margin-bottom: 8px;
		}
		background-color: getColor("white");
		outline: 1px inset #eee;
		.field-input {
			& > input {
				height: auto;
			}
		}
}