.flex {
	display: flex !important;
}

.flex-row {
	display: flex;
	flex-direction: row !important;
}

.flex-column {
	display: flex;
	flex-direction: column !important;
}

.flex-start {
	display: flex;
	justify-content: flex-start !important;
}

.flex-end {
	display: flex;
	justify-content: flex-end !important;
}

.flex-between {
	display: flex;
	justify-content: space-between !important;
}

.flex-around {
	display: flex;
	justify-content: space-around !important;
}

.flex-center {
	display: flex;
	justify-content: center !important;
}

.flex-align-center {
	display: flex;
	align-items: center !important;
}