.loadmask {
    z-index: $z-loadmask;
    position: absolute;
    top:0;
    left:0;
    -moz-opacity: 0.5;
    opacity: .50;
    background-color: #EEE;
    width: 100%;
    height: 100%;
    zoom: 1;
}
.loadmask-msg {
    z-index: $z-loadmask-message;
    position: absolute;
    top: 0;
    left: 0;
    border:1px solid #6593cf;
    background: #c3daf9;
	padding:2px;

	div {
		padding:5px 10px 5px 25px;
		background: red;
		background: white url('/images/ajax-loader.gif') no-repeat 5px 5px;
		line-height: 16px;
		border: 1px solid #137488;
		color:#222;
		font: normal 12px arial, helvetica, sans-serif;
		cursor:wait;
	}
}
.masked {
    overflow: hidden !important;
}
.masked-relative {
    position: relative !important;
}
.masked-hidden {
    visibility: hidden !important;
}