

[class^=btn] {
	outline: none;
	border: none;
	cursor: pointer;
	cursor: hand;

	&.btn-primary {
		color: getColor("white");
		border: 1px solid getColor("primary");
		background-color: getColor("primary");
		&:hover, &:focus, &:active {
			background-color: getColor("primary-hover");
			
		}
		i.fa {
			color: getColor("white");
		}
	}
	&.btn-confirm {
		display: block;		
		text-align: center;
		align-self: center;
		color: white;
		&.error {
			background-color: getColor("state-error-background");
		}
		&.success {
			background-color: getColor("state-success-background");
		}
	}
}

.button-set {
	button {
		margin-left: 5px;
	}
}