$margin-list: 4 8 16 20 32 100;

@each $m in $margin-list {
	.margin-#{$m} {
		margin: #{$m}px;
	}
	.margin-top-#{$m} {
		margin-top: #{$m}px;
	}
	.margin-right-#{$m} {
		margin-right: #{$m}px;
	}
	.margin-bottom-#{$m} {
		margin-bottom: #{$m}px;
	}
	.margin-left-#{$m} {
		margin-left: #{$m}px;
	}
}