@mixin __hr_reset {
	border: none;
	background-color: transparent;
}

hr {
	color: #1faece;
	background-color: #1faece;
	border: 1px solid #1faece;
	width: 100%;

	&.thin {
		@include __hr_reset;
		border-bottom-style: solid;
		border-width: 1px;
		height: 5px;
		margin-bottom: 5px;
		border-color: getColor("border-active-color");
	}

	&.separator {
		@include __hr_reset;
		background-image: url("/images/icons/linha.png");
		background-repeat:repeat-x;
		padding-top:10px;
		margin: 0;
	}

	&.dotted {
		@include __hr_reset;
		width: 100%;
		border-bottom-width: 1px;
		border-bottom-style: dotted;
		border-color: gray;

		&.double {
			border-bottom-width: 2px;
		}
	}
}