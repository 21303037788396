.dialog-wrapper {
  .dialog-window {
    padding-top: 3px;
    padding-left: 3px;
    padding-right: 3px;
    min-width: auto;
    box-shadow: 1px 1px 30px grey;
    overflow-x: visible;

    .dialog-title {
      min-height: 32px;
    }

    .dialog-content {

      width: calc(100% - 15px);
      height: auto;
      & > div {
          width: 100%;
      }

      .dialog-content-inner {
        padding: .5em 1em;
      }
      display: flex;
      button {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1em;
      }
      .field {
        border-color: transparent;
      }
    }

    .dialog-title {
      display: flex;
      min-height: 38px;
      padding-top: 3px;
      padding-bottom: 3px;
      position: relative;
      float: left;
      @include border-all-radius(3px);
      border: 1px solid getColor("primary");
      color: #ffffff;
      font-weight: bold;
      background-color: getColor("primary");
      @include linear-gradient(getColor("primary"), getColor("primary-hover"));
      a {
        color: #ffffff;
      }
      margin-left: 0;
      margin-right: 0;
      .title-text {
        color: white;
        font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 13px;
      }
    }
  }

  .button-close {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3px;
    width: 24px;
    height: 20px;

    position: relative;
    padding: 0;
    margin-right: .1em;
    text-decoration: none !important;
    cursor: pointer;
    text-align: center;
    zoom: 1;
    overflow: visible;
    @include border-all-radius(3px);
    padding: 1px;
    color: transparent;
    overflow: hidden;
    span {
      background-image: url(/images/ui-icons_d8e7f3_256x240.png);
      background-position-x: -96px;
      background-position-y: -128px;
      width: 16px;
      height: 16px;
      margin-top: 2px;
      margin-left: 3px;
      display: block;
    }
    background-color: transparent;
    border-color: transparent;
    width: 24px;
    &:hover {
      @include background-glass("d0e5f5", 75);
      span {
        background-image: url(/images/ui-icons_217bc0_256x240.png) !important;
      }
      border: 1px solid getColor("border-active-color");
      font-weight: bold;
      color: getColor("link-hover");
    }
  }

  .dialog-buttons {
    text-align: left;
    border-width: 1px 0 0 0;
    background-image: none;
    margin: .5em 0 0 0;
    padding: .3em 1em .5em .4em;
    border: 1px solid #a6c9e2;
    background: #fcfdfd url("/images/ui-bg_inset-hard_100_fcfdfd_1x100.png") 50% bottom repeat-x;
    color: #222222;
    zoom: 1;
    display: flex;
    justify-content: flex-end;

    button {
      margin: .5em .4em .5em 0;
      cursor: pointer;
    }
  }
}