.text-center {
	display: flex;
    flex-direction: column;
    justify-content: center;
}

.text-bold {
	font-weight: bold;
}

.text-big {
	font-weight: bold;
	color: getColor("big-text");
}