@font-face {
	font-family: 'icomoon';
	font-display: swap;
	src:  url(/fonts/icomoon.eot?x3tmz3);
	src:  url(/fonts/icomoon.eot?x3tmz3#iefix) format('embedded-opentype'),
		url(/fonts/icomoon.ttf?x3tmz3) format('truetype'),
		url(/fonts/icomoon.woff?x3tmz3) format('woff'),
		url(/fonts/icomoon.svg?x3tmz3#icomoon) format('svg');
	font-weight: normal;
	font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak-as: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 70px;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

$icons: (
	RNE_Icons-menu: "\e94c",
	map: "\e94d",
	MarkerStop: "\e94a",
	MarkerBilhete: "\e94b",
	RNE_Icons-70: "\e948",
	Info-Filled: "\e949",
	Rflex-card: "\e947",		
	Empty-search: "\e944",		
	TV: "\e945",		
	Wifi: "\e946",		
	Bus: "\e900",		
	Enlace: "\e901",
	Back-arrow: "\e902",
	Front-arrow: "\e903",
	Up-arrow: "\e904",
	Down-arrow: "\e905",
	Plus: "\e906",
	Minus: "\e907",
	Close: "\e908",
	Check: "\e909",
	Frequencia: "\e90a",
	Check-2: "\e90b",
	Error: "\e90c",
	Ida: "\e90d",
	Info: "\e90e",
	Perigo: "\e90f",
	Questão: "\e910",
	Notificação: "\e911",
	Mensagem: "\e912",
	Distancia: "\e913",
	Logout: "\e914",
	wifi: "\e915",
	Telefone: "\e916",
	Calendario: "\e917",
	Relogio: "\e918",
	Link: "\e919",
	Mapa: "\e91a",
	Alvo: "\e91b",
	Settings: "\e91c",
	Eliminar: "\e91d",
	Gestão-de-kms: "\e91e",
	Filtro: "\e91f",
	Bagagem: "\e920",
	Chart: "\e921",
	Cadeado: "\e922",
	Zoom-out: "\e923",
	Zoom-in: "\e924",
	Search: "\e925",
	Foto: "\e926",
	Casa: "\e927",
	Passageiro: "\e928",
	Desconto: "\e929",
	Carrinho: "\e92a",
	Transporte: "\e92b",
	Pagamento: "\e92c",
	Assistencia: "\e92d",//old icon
	ContactCenter: "\e94f",//e94e
	Partilha: "\e92e",
	Recibo: "\e92f",
	Google: "\e930",
	Facebook: "\e931",
	Dribble: "\e932",
	Behance: "\e933",
	Linkedin: "\e934",
	Twitter: "\e935",
	Instagram: "\e936",
	Vimeo: "\e937",
	Youtube: "\e938",
	Ida-e-volta: "\e939",
	Dropdown: "\e93a",
	Link-2: "\e93b",
	Bilhete: "\e93c",
	Lugar: "\e93d",
	Condutor: "\e93e",
	Entidade: "\e93f",
	Pagamento-2: "\e940",
	Referencia: "\e941",
	Animais: "\e942",
	Histórico: "\e943"
);

@each $name, $value in $icons {
	.icon-#{$name}:before {
		content: $value;
	}
	:export {
		#{$name}: #{$value};
	}
}