

.dialog-wrapper {
	.dialog-window {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		background-color: getColor("white");
		min-height: fit-content;
		padding: 0;
		max-height: 100%;
		max-width: 100%;
		overflow-y: visible;
		h1, p {
			font-weight: 500;
			line-height: 1.1;
			margin-bottom: 16px;
			color: getColor("black");
			text-align: center;
		}
		h1 {
			font-size: 1.25rem;
		}
		p {
			font-size: 1rem;
		}
		form {
			width: 100%;
		}
		.confirm {
			display: flex;
			justify-content: center;
			i {
				color: getColor("white");
			}
		}
	}

	&.fullscreen {
		.dialog-window {
			min-height: 100%;
			min-width: 100%;
		}
	}
}