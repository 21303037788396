
.dialog-wrapper {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	left:50%;
	z-index: $z-modal;
	&:not(.no-dialog) {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0,0,0,0.3);
		.dialog-wrapper {
			width:100vw;
			height:100vh;
			position:absolute;
			top:0;
			left:0;
			background-color:transparent;
		}
	}
	&.no-dialog .dialog-window {
		min-height: 400px;
	}
	&.no-dialog {
		.button-close {
			display: none;
		}
	}
	opacity: 0;
	transition: .4s;
	&.not-mounted {
		opacity: 0;
	}
	&.mounted {
		opacity: 1;
	}
	&.fullscreen {
		@include sizes("desktop") {
			padding: 0;
			&.mounted {
				padding: 20px 40px;
			}
		}
		@include sizes("mobile") {
			padding: 0;
			&.mounted {
				padding: 10px;
			}
		}
	}

	&:not(.fullscreen) {
		.dialog-window {
			@include sizes("desktop") {
			}
			@include sizes("mobile") {
				min-width: 100%;
				width: 100%;
			}
		}
	}
	&.not-draggable {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
}